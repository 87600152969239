/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, PriceWrap, Button, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Plnění klimatizací "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--50 pt--60" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--72" content={"Plnění klimatizace R134a"}>
              </Title>

              <Text className="text-box fs--18" content={"Provádíme servis, čištění a plnění klimatizaci s chladivem R134a&nbsp; - 1234yf&nbsp;<br><span style=\"color: rgb(249, 35, 5);\">Osobní vozidla - dodávky - nákladní vozidla - pracovní stroje&nbsp;<br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"obsah-1"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Jaké služby nabízíme a zajišťujeme pro klimatizaci vašeho vozu?</span>\n<br>- Odsátí a recyklace starého chladiva ze systému klimatizace a jeho následná likvidace<br>- Vyčištění klimatizace od nečistot a škodlivých mikroorganismů (dezinfekce ozonem)\n<br>- Aplikace kontrastní UV látky pro odhalení netěsností\nKontroly těsnosti klimatizace a detekce úniků chladiva\n<br>- Plnění klimatizací speciálním olejem (dle typu vozu) a chladivem R134a - 1234yf<br>- Dezinfekce ozonem vzduchových průduchů v autě\n<br>- Výměny kabinových (pylových) filtrů\n<br>- Opravy zjištěných vad a netěsností systému klimatizace\nVýměny řemenů, chladičů, kompresorů a jiných dílů klimatizace<br>- Doplnění oleje a chladiva\n<br><span style=\"font-weight: bold;\">Naši technici pravidelně absolvují odborná školení a zkoušky zaměřené na autoklimatizace, které nařizují směrnice a normy EU ze dne 17. května 2006 o emisních a klimatizačních systémech motorových vozidel.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"lj8isjerdg"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Klimatizace&nbsp;"}>
              </Title>

              <Text className="text-box" content={"O vaši autoklimatizaci se postaráme kompletně. Každý den z našeho autoservisu odjíždí zákazníci s doplněnou, vyčištěnou a udržovanou klimatizací. Na svých cestách tak mají vždy optimální teplotu bez zápachů i zdraví škodlivých bakterií, které jsou ve vozidle.\nEkologická chladiva R134a nebo 1234yf&nbsp; a moderní přístroje značky wurth zaručují profesionální úroveň čištění a plnění klimatizace. Veškeré práce navíc máme hotové v krátkých termínech – základní servis klimatizace trvá přibližně 60 minut podle objemu náplně a závady systému.\nVašemu vozu se můžeme věnovat 5 dní v týdnu od 7.00 do 15.30 h. Menší opravy a práce provádíme na počkání. Navíc nabízíme i expres služby na objednání a to i v sobotu do 18.00 h. Díky pravidelné údržbě klimatizace snižujeme riziko poruchy. Dezinfekcí ozonem také odstraníme zápach, který způsobují bakterie, viry a plísně uvnitř vozu. Odhalením netěsností snížíme náklady na údržbu klimatizace, její opotřebení a následnou cenu opravy."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"2c2a12guvwi"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/20344/36e1a115b0b04a8d9736222626562918.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/20344/1576e8c840734037b8976ecd1e353d41_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/20344/1576e8c840734037b8976ecd1e353d41_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/1576e8c840734037b8976ecd1e353d41_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/20344/69ea075869a9470099c3f9304d4e28d0.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"uo6oibuj76d"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Plnění klimatizace včetně oleje"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"900,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Chladivo 1 g&nbsp; staré chladivo r134a&nbsp;<br>Nové chladivo 1234yf<br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"3.5,— Kč<br><br>7.5 ,- Kč&nbsp;<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Dezinfekce klimatizace a interiéru vozu ozonem 15 min"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"300,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Diagnostika uniku dusíkem&nbsp; v ceně 15 min hledání&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"700,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"15 min hledání&nbsp; úniku navíc&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"200,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1odcdv6 css-42e4bw pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Máte dotaz?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22" url={"https://autoservisblaza.cz/kontaktni-formular/"} href={"https://autoservisblaza.cz/kontaktni-formular/"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Autoservis Bláža Ostrava - Radvanice&nbsp;</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Pro Vaše auto uděláme vše, co budeme moct.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Jsme Malý a nezávislý autoservis osobních i užitkových vozidel. Měření a seřízení geometrie kol Servis čištění a plnění klimatizací Rychloservis Pneuservis Nástřiky podvozku a dutin Strojní regenerace filtru pevných částic DPF a jiné.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"&nbsp;Lihovarská 1378/11, Ostrava - Radvanice Areál Bánských strojíren ( BASTRO )\nTel: +420 777 321 587&nbsp;<br>"}>
              </Text>

              <Text className="text-box" content={"IČ: 63471710<span style=\"color: white;\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}